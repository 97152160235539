// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blogpage-js": () => import("./../../../src/templates/blogpage.js" /* webpackChunkName: "component---src-templates-blogpage-js" */),
  "component---src-templates-blogpostpage-js": () => import("./../../../src/templates/blogpostpage.js" /* webpackChunkName: "component---src-templates-blogpostpage-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-privacypage-js": () => import("./../../../src/templates/privacypage.js" /* webpackChunkName: "component---src-templates-privacypage-js" */),
  "component---src-templates-termspage-js": () => import("./../../../src/templates/termspage.js" /* webpackChunkName: "component---src-templates-termspage-js" */),
  "component---src-templates-whatisaltradypage-js": () => import("./../../../src/templates/whatisaltradypage.js" /* webpackChunkName: "component---src-templates-whatisaltradypage-js" */)
}

